.select-cities {
    position: fixed;
    bottom: 30px;
    right: 40px;
    z-index: 20;
    overflow: hidden;
    border-radius: 60px;
    background: rgba(29, 33, 38, 0.6);
    border: 0.5px solid $white;
    backdrop-filter: blur(15px);

    @media screen and (max-width: 767px) {
        position: fixed;
        bottom: initial;
        left: initial;
        top: 34px;
        right: 20px;
        margin-left: 10px;
        width: calc(100% - 40px);
        max-width: 120px;
    }

    &.is-active {
        border-radius: 15px;
        background: linear-gradient(180deg, rgba(21, 22, 26, 0.5) -31.63%, rgba(43, 44, 47, 0.5) 82.65%);
        backdrop-filter: blur(20px);

        @media (max-width: 767px) {
          max-width: 100vw;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100px;
            background: linear-gradient(2.43deg, #111111 1.82%, rgba(17, 17, 17, 0) 88.49%);
        }

        .select-cities-button {
            .button-icon {
                transform: rotate(180deg);
            }
        }

        .select-cities-content {
            height: 350px;
            padding: 12px 12px 100px;
            border-color: rgba(255, 255, 255, 0.3);
        }
    }

    &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 60px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: 767px) {
          max-width: 100vw;
          padding: 10px 20px;

            .button-text {
                font-size: 18px;
                line-height: 22px;
            }
        }

        .button-text {
            margin-right: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: $white;

            @media (max-width: 767px) {
              font-size: 16px;
              margin-right: 6px;
              display: none;
            }

          &.mobile {
            display: none;

            @media (max-width: 767px) {
              display: inline-block;
            }
          }
        }

        .button-icon {
            width: 24px;
            height: 24px;
            fill: $white;
            flex-shrink: 0;
        }
    }

    &-content {
        position: relative;
        overflow-y: scroll;
        height: 0;
        padding: 0;
        border-top: 1px solid transparent;
        transition: all 0.3s ease-in-out;
    }

    .city-item {
        display: flex;
        align-items: stretch;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }

        .city-item-image {
            width: 100px;
            height: 100px;
            object-fit: cover;
            object-position: center;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .city-item-content {
            margin-left: 10px;
        }

        .city-item-name {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $white;
        }

        .city-item-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: $grey;
        }

        .city-item-link {
            display: inline-block;
            margin-top: 15px;
            padding: 10px 30px;
            border: 0.25px solid $white;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: $black;
            background: $tile;
            box-shadow: -12px -12px 24px rgba(62, 62, 62, 0.5), 12px 12px 24px rgba(30, 30, 30, 0.5);
            border-radius: 27px;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: $beige;
            }
        }

        .city-item-message {
            display: inline-block;
            width: 100%;
            color: $tile;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            background: transparent;
            border-radius: 8px;
            transition: all 0.4s ease-in-out;
        }
    }
}
