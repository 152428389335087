.about-wrapper {
  color: $light;
  padding: 135px 0 500px;
  margin-bottom: 50px;
  background-image: url('../img/site/about-bg.jpg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;

  @media (max-width: 767px) {
    padding: 70px 0 300px;
  }

  .padded-section {
    padding: 0 40px;
  }

  .about-header {
    display: flex;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    h1 {
      margin-right: 40px;

      @media (max-width: 1024px) {
        margin: 0 0 20px;
      }
    }

    .about-header-content {
      max-width: 902px;
      margin-left: auto;

      @media (max-width: 1024px) {
        max-width: initial;
        margin: 0;
      }

      img {
        width: 100%;
        margin-bottom: 50px;
        border-radius: 30px;

        @media (max-width: 1024px) {
          margin-bottom: 25px;
        }
      }

      .title4 {
        text-transform: uppercase;
      }
    }
  }

  .about-shows {
    width: 100%;
    margin: 100px auto 175px;
    padding-top: 0;

    @media (max-width: 1024px) {
      margin: 50px auto 82px;
    }

    .carousel-navigation {
      @media (max-width: 767px) {
        display: none;
      }
    }

    .show-card {
      position: relative;
      margin-right: 20px;

      .show-img {
        position: relative;
        width: 325px;
        height: 325px;
        border-radius: 8px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .show-status {
          position: absolute;
          top: 18px;
          right: 18px;
          border-radius: 35px;
          padding: 6px 22px 6px 24px;
          text-transform: uppercase;

          @extend .p16;

          &.is-ongoing {
            background: $tile;
            color: $black;
          }

          &.is-soon {
            background: $colorAccent2;
          }

          &.is-past {
            background: $grey;
          }
        }
      }

      .show-info {
        margin-top: 16px;

        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 130%;
        }

        .show-ticketing-message {
          color: $tile;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          text-transform: uppercase;
          background: transparent;
          border-radius: 8px;
          transition: all 0.4s ease-in-out;
        }

        .show-date {
          color: $grey;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 130%;
          }
        }
      }
    }
  }

  .about-awards {
    h2 {
      margin-bottom: 40px;
      text-transform: uppercase;

      @media (max-width: 1100px) {
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 30px;
      }
    }

    .award-table {
      @extend .p24;

      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 130%;
      }

      .award-divider {
        position: relative;
        height: 1px;
        background-color: $grey;
      }

      .award-row {
        display: flex;
        text-align: left;
        padding: 13px 0;
      }

      .award-title {
        display: inline-block;
        width: 33%;
        font-weight: bold;
        color: $white;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: $tile;
        }
      }

      .award-desc {
        width: 40%;
      }

      .award-year {
        margin-left: auto;
      }
    }
  }

  .about-img-section {
    margin-top: 150px;

    @media (max-width: 1024px) {
      margin-top: 100px;
    }

    img {
      width: 100%;
    }
  }

  .about-team {
    margin-top: 150px;
    margin-bottom: 210px;

    @media (max-width: 1024px) {
      margin-top: 100px;
    }

    h2 {
      max-width: 830px;
      text-transform: uppercase;

      @media (max-width: 1100px) {
        font-size: 48px;
        line-height: 58px;
      }
    }

    .team-table {
      margin: 50px 0 150px;

      @media (max-width: 767px) {
        margin-bottom: 80px;
      }

      .team-divider {
        position: relative;
        height: 1px;
        background-color: $grey;
      }

      .team-row {
        display: flex;
        text-align: left;
        align-items: center;
        padding: 20px 0;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          padding: 30px 0;
        }

        .team-logo-part {
          align-self: flex-start;
          min-width: 30%;
          margin-right: 40px;

          @media (max-width: 767px) {
            margin: 0 0 40px 0;
          }

          .team-type {
            @extend .p24;
            color: $grey;
            margin-bottom: 50px;

            @media (max-width: 1024px) {
              font-size: 20px;
              line-height: 130%;
              margin-bottom: 30px;
            }
          }

          img {
            max-width: 100%;
          }
        }

        .team-content {
          @extend .p18;

          @media (max-width: 1024px) {
            font-size: 16px;
            line-height: 130%;
          }

          p {
            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }

          a {
            font-weight: bold;
            transition: color 0.3s ease;

            &:hover {
              color: $tile;
            }
          }

          &.logo-container-row {
            display: flex;
            align-items: center;

            .logo-container {

              &:not(:last-child) {
                margin-right: 50px;
              }
            }
          }
        }

        &.is-scrollable {
          flex-direction: column;

          .logo-container-row {
            //display: block;
            max-width: 100%;
            overflow-x: scroll;
          }

          .team-logo-part {
            width: 100%;
            margin: 0;
          }
        }
      }
    }

    .team-dropdowns {

      .team-dropdown {
        overflow: hidden;

        &.is-open {
          .team-arrow-icon {
            transform: rotate(-180deg);
          }

          .team-dropdown-content {
            max-height: 100%;
            padding: 0 0 40px;
            opacity: 1;
          }
        }
      }

      .team-dropdown-title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .title3 {
          text-transform: uppercase;
          cursor: pointer;

          @media (max-width: 1100px) {
            font-size: 32px;
            line-height: 38px;
          }

          @media (max-width: 767px) {
            width: calc(100% - 68px);
          }

          @media (max-width: 500px) {
            font-size: 24px;
          }
        }

        .team-arrow-icon-wrapper {
          position: relative;
          overflow: hidden;
          width: 58px;
          height: 58px;
          margin-left: 30px;
          border-radius: 50%;
          cursor: pointer;

          @media (max-width: 1100px) {
            width: 50px;
            height: 50px;
          }

          @media (max-width: 767px) {
            width: 38px;
            height: 38px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70%;
            height: 70%;
            background: $tile;
            border: 0.5px solid $white;
            filter: blur(15.5px);
            border-radius: 27px;
            opacity: 0;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease;
          }

          &:hover {
            background: transparent;

            &::before {
                opacity: 0.6;
            }
          }
        }

        .team-arrow-icon {
          position: relative;
          z-index: 1;
          width: 58px;
          height: 58px;
          flex-shrink: 0;
          cursor: pointer;
          transition: transform .3s ease-in-out;

          @media (max-width: 1100px) {
            width: 50px;
            height: 50px;
          }

          @media (max-width: 767px) {
            width: 38px;
            height: 38px;
          }
        }
      }

      .team-dropdown-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;
        border-top: 1px solid $light;
        max-height: 0;
        padding-bottom: 0;
        transition: max-height 0.25s ease-in-out, padding-bottom 0.25s ease-in-out, opacity 0.25s ease-in-out;
        will-change: max-height;
        opacity: 0;

        .team-column {
          width: calc(33% - 20px);
          margin: 23px 0;

          @media (max-width: 1024px) {
            width: calc(50% - 20px);
          }

          @media (max-width: 500px) {
            width: 100%;
          }

          p {
            @extend .p20;

            @media (max-width: 767px) {
              font-size: 16px;
              line-height: 130%;
            }

            &.team-main-title {
              font-weight: bold;
              margin-bottom: 14px;
            }
          }
        }
      }
    }
  }

  .about-contact {

    .contact-columns {
      display: flex;
      flex-wrap: wrap;
      gap: calc(20% - 90px);
      margin-top: 55px;

      @media (max-width: 1130px) {
        gap: calc(15% - 110px);
      }

      @media (max-width: 1024px) {
        gap: calc(25% - 110px);
      }

      @media (max-width: 625px) {
        gap: 100%;
      }

      .contact-column {
        margin-bottom: 40px;
        flex: 1;

        p {
          @extend .p18;

          &.contact-type {
            @extend .p28;
            color: $tile;
            margin-bottom: 20px;
          }

          &.contact-name {
            font-weight: bold;
          }
        }

        a {
          display: block;
          transition: color .3s ease-in-out;

          &:hover {
            color: $tile;
          }
        }
      }
    }
  }
}
