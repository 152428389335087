[data-aos="border-bottom"] {
    transform: scaleX(0);
    transform-origin: left center;
    transition-property: transform;
  
    &.aos-animate {
        transform: scaleX(1);
    }
}

[data-aos="reveal-image-left"] {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $black;
        transform: scaleX(1);
        transition-property: transform;
        transform-origin: left center;
        transition: all 0.6s ease-in-out;
    }

    &.aos-animate {
        &::after {
            transform: scaleX(0);
        }
    }
}

[data-aos="reveal-image-right"] {
    position: relative;
    transition-property: transform;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $black;
        transform: scaleX(1);
        transition-property: transform;
        transform-origin: right center;
        transition: all 0.6s ease-in-out;
    }

    &.aos-animate {
        &::after {
            transform: scaleX(0);
        }
    }
}