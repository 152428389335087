.testimonials {
    padding-top: 100px;
    padding-bottom: 230px;

    @media (max-width: 600px) {
        padding-top: 50px;
        padding-bottom: 130px;
    }

    &-title {
      @extend .title2;
      text-transform: uppercase;
      color: $white;

      @media (max-width: 768px) {
        font-size: 60px;
        line-height: 72px;
      }

      @media (max-width: 475px) {
        font-size: 32px;
        line-height: 120%;
      }
    }

    &-container {
        position: relative;
        width: 100%;
        max-width: 1000px;
        margin: 50px auto 0;

        @media screen and (max-width: 768px) {
            margin: 20px auto 0;
        }
    }

    .custom-arrow-nav {
      display: flex;
      align-items: center;
      margin: 30px 0 0;

      .custom-arrow-container {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 70%;
          height: 70%;
          background: $tile;
          border: 0.5px solid $white;
          filter: blur(15.5px);
          border-radius: 27px;
          opacity: 0;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease;
        }

        &:hover {
          background: transparent;
          &::before {
            opacity: 0.6;
          }
        }

        &:first-child {
          margin-right: 20px;
        }

        svg {
          position: relative;
          z-index: 1;
          cursor: pointer;
          width: 70px;
          height: 70px;

          @media (max-width: 767px) {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .testimonial {
        width: 100%;

        &-content {
            width: 100%;
            margin: 0 auto;
            padding: 0 150px;

            @media (max-width: 600px) {
                padding: 0;
            }
        }

        &-name {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $white;
        }

        &-text {
            margin-top: 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 130%;
            color: $white;

            @media (max-width: 600px) {
                margin-top: 20px;
                font-size: 18px;
                line-height: 140%;
            }
        }

        &-rating {
            margin-top: 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 34px;
            color: $tile;

            @media (max-width: 600px) {
                margin-top: 20px;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    &-divider {
        position: relative;
        height: 1px;
        margin-top: 100px;
        background-color: $grey;

        @media screen and (max-width: 767px) {
            margin-top: 50px;
        }
    }
}
