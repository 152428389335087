.footer {
    background: $black;

    @media screen and (max-width: 1024px) {
        padding: 0;
    }

    &-top,
    &-bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    &-top {
        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }

        .footer-left {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 32px 23px 23px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background: rgba(255, 255, 255, 0.3);
            }

            @media screen and (max-width: 1024px) {
                width: 100%;
                padding: 30px 0 75px;

                &::after {
                    content: none;
                }
            }
        }

        .footer-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 34px 75px 20px 20px;

            @media screen and (max-width: 1024px) {
                width: 100%;
                padding: 0 0 35px;
            }
        }
    }

    &-bottom {
        padding: 23px;

        @media screen and (max-width: 1024px) {
            align-items: flex-start;
            padding: 15px 0 35px;
        }

        @media only screen and (max-width: 767px) {
            padding: 15px 0 140px;
        }

        .footer-left {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1024px) {
                width: 50%;
            }
        }

        .footer-right {
            @media screen and (max-width: 1024px) {
                display: flex;
                flex-direction: column;
                width: 50%;
            }
        }
    }

    &-left {
        width: calc((100% / 3) * 2);
    }

    &-right {
        width: calc(100% / 3);
    }

    &-nav {
        display: flex;
        //align-items: center;

        .nav-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            margin-right: 60px;
            padding: 0;
            list-style: none;

            li {
                margin-right: 60px;
                margin-bottom: 30px;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            a {
                color: $white;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 100%;
                text-transform: uppercase;
                transition: color 0.3s ease;

                &:hover {
                    color: $colorAccent2;
                }
            }

            @media screen and (max-width: 1024px) {
                margin-right: 20px;

                li {
                    margin-right: 20px;
                    margin-bottom: 10px;
                }

                a {
                    font-size: 16px;
                }
            }
        }
    }

    &-cities {
        position: relative;
    }

    &-cities-title {
        display: flex;
        align-items: center;
        color: $white;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 100%;
        cursor: pointer;
        text-transform: uppercase;
        transition: all 0.4s ease-in-out;

        &:hover {
            color: $colorAccent2;

            svg path {
                fill: $colorAccent2;
            }
        }

        svg {
            margin-left: 10px;

            path {
                fill: $white;
                transition: all 0.4s ease-in-out;
            }
        }

        @media screen and (max-width: 1024px) {
            font-size: 16px;
        }
    }

    &-cities-dropdown {
        position: absolute;
        top: calc(100% + 6px);
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% + 80px);
        border-radius: 8px;
        background: $darkGrey;
        z-index: 1;
        transform-origin: top center;
        transform: translateX(-50%) scaleY(0);
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in-out;

        @media (max-width: 767px) {
            width: auto;
        }

        &.is-active {
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%) scaleY(1);
        }

        li {
            width: 100%;
            padding: 7px 3px;
        }

        a {
            display: inline-block;
            width: 100%;
            padding: 5px 8px;
            color: $white;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            text-transform: uppercase;
            background: transparent;
            border-radius: 8px;
            transition: all 0.4s ease-in-out;

            &:hover {
                background: $colorAccent2;
            }
        }

        p {
            display: inline-block;
            width: 100%;
            padding: 5px 8px;
            color: $white;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            text-transform: uppercase;
            background: transparent;
            border-radius: 8px;
            transition: all 0.4s ease-in-out;

            span {
                font-size: 12px;
                line-height: 140%;
                color: $tile;
            }
        }
    }

    &-cities-dropdown-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;

        .logo {
            width: 367px;
        }

        img {
            display: block;
            width: 100%;
        }

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    &-logo-mobile {
        display: none;
        width: 264px;
        margin: 0 auto;

        img {
            display: block;
            width: 100%;
        }

        @media screen and (max-width: 1024px) {
            display: inline-block;
        }
    }

    &-newsletter-title {
        margin-bottom: 90px;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
        color: $white;

        @media screen and (max-width: 1024px) {
            margin-bottom: 30px;
            font-size: 28px;
            line-height: 130%;
        }
    }

    &-newsletter {
        margin-bottom: 90px;

        @media screen and (max-width: 1024px) {
            margin-bottom: 70px;
        }

        &-form {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 11px;

            input {
                width: 100%;
                padding: 10px 0;
                border: none;
                border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                background: transparent;
                color: $white;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                transition: all 0.4s ease-in-out;

                &:focus {
                    outline: 0;
                }

                &::placeholder {
                    color: $grey;
                }
            }

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 17px;
                padding: 6px 15px;
                background: $colorAccent1;
                border: 0.25px solid $white;
                box-shadow: -12px -12px 24px rgba(62, 62, 62, 0.7), 12px 12px 24px rgba(30, 30, 30, 0.7);
                border-radius: 27px;
                cursor: pointer;
                transition: all 0.4s ease-in-out;

                &:hover {
                    background: $beige;
                }
            }
        }

      .form-error,
      .form-success {
        display: none;
        font-size: 14px;
        font-family: $fontNeueMontreal;

        &.is-visible {
          display: inline-block;
        }
      }

      .form-error {
        color: $error;
      }

      .form-success {
        color: $success;
      }

        &-text {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: $light;
        }
    }

    &-socials {
        @media screen and (max-width: 1024px) {
            margin-bottom: 70px;
        }

        .socials-list {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin-right: 5px;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.4s ease-in-out;

                &:hover {
                    svg path {
                        fill: $white;
                    }
                }

                svg {
                    path {
                        fill: $colorAccent1;
                        transition: all 0.4s ease-in-out;
                    }
                }
            }
        }
    }

    &-copyright {
        margin-right: 75px;
        color: $grey;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }

    &-small-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;

        &.for-mobile {
            display: none;
        }

        li {
            margin-right: 50px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        a {
            display: inline-block;
            color: $light;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            transition: color 0.3s ease;

            &:hover {
                color: $colorAccent2;
            }
        }

        @media screen and (max-width: 1024px) {
            display: none;

            &.for-mobile {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                li {
                    margin-right: 0;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                a {
                    font-weight: 700;
                }
            }
        }
    }

    &-credits-link {
        display: inline-block;
        margin-left: 20px;
        color: $light;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        transition: color 0.3s ease;

        &:hover {
            color: $colorAccent2;
        }

        @media screen and (max-width: 1024px) {
            margin-left: 0;
            margin-top: 10px;
            font-weight: 700;
        }
    }
}
