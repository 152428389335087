// Header
.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    transition: all 0.6s ease-in-out;

    &.is-hidden {
        transform: translateY(-100%);
    }

    &.is-sticky {
        position: fixed;
        background: $black;
    }

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &-logo {
        img {
            display: block;
            width: 226px;

            @media (max-width: 1100px) {
                width: 166px;
            }
        }
    }

    &-nav {
        display: flex;
        align-items: center;
        list-style: none;

        ul.nav-list {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 9px 40px;
            list-style: none;
            border-radius: 54px;
            background: linear-gradient(180deg, rgba(21, 22, 26, 0.5) -31.63%, rgba(43, 44, 47, 0.5) 82.65%);
            backdrop-filter: blur(20px);

            li.nav-item {
                position: relative;
                margin-right: 30px;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                text-transform: uppercase;
                color: $white;
                cursor: pointer;
                transition: all 0.3s ease;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 80%;
                    height: 100%;
                    background: $tile;
                    border: 0.5px solid $white;
                    filter: blur(15.5px);
                    border-radius: 27px;
                    opacity: 0;
                    transform: translate(-50%);
                    transition: all 0.3s ease;
                }

                &:hover {
                    &::before {
                        opacity: 0.6;
                    }
                }

                &.is-active {
                    &::before {
                        opacity: 0.6;
                        background: $white;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &-right {
        display: flex;
        align-items: center;

      &.is-home-header {
        @media (max-width: 767px) {
          margin-left: auto;
          margin-right: 130px;
        }
      }
    }

    &-btn {
        display: inline-block;
        margin-right: 56px;
        padding: 10px 50px;
        border: 0.5px solid $grey;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $white;
        background: linear-gradient(180deg, rgba(21, 22, 26, 0.5) -31.63%, rgba(43, 44, 47, 0.5) 82.65%);
        backdrop-filter: blur(20px);
        border-radius: 54px;

      &.desktop-btn {
        @media (max-width: 767px) {
          display: none;
        }
      }

      &.mobile-btn {
        @media (min-width: 768px) {
          display: none;
        }
      }
    }

    &-language {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: $white;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            color: $colorAccent2;
        }

        &.homepage {
            display: none;
            padding: 13px 12px;
            border-radius: 50px;
            background: rgba(29, 33, 38, 0.6);
            backdrop-filter: blur(15px);
        }
    }

    &-mobile-menu {
        position: absolute;
        top: 50%;
        right: 20px;
        display: none;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        border: none;
        border-radius: 50px;
        background: rgba(29, 33, 38, 0.6);
        backdrop-filter: blur(15px);
        transform: translateY(-50%);
        cursor: pointer;
        appearance: none;
    }

    @media screen and (max-width: 1100px) {
        justify-content: center;

        &-container {
            padding: 26px 20px;
        }

        &-logo {
            width: 166px;
        }

        &-nav {
            display: none;
        }

        &-btn {
            display: none;
        }

        &-language {
            display: none;

            &.homepage {
                display: block;
            }
        }

        &-mobile-menu {
            display: flex;
        }
    }
}

// Mobile menu
.mobile-menu {
    position: fixed;
    inset: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background: rgba(29, 33, 38, 0.6);
    backdrop-filter: blur(15px);
    transform: translateY(100%);
    transition: all 0.6s ease-in-out;

    &.is-active {
        transform: translateY(0);
    }

    &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 26px 0;
    }

    &-logo {
        width: 166px;
        margin-left: auto;

        img {
            width: 100%;
        }
    }

    &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        margin-left: auto;
        border: none;
        border-radius: 50px;
        background: rgba(29, 33, 38, 0.6);
        backdrop-filter: blur(15px);
        cursor: pointer;
        appearance: none;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &-nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        list-style: none;

        ul.nav-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0;
            list-style: none;

            li.nav-item {
                width: 100%;
                padding-top: 13px;
                padding-bottom: 13px;
                border-bottom: 1px solid rgba($white, 0.3);
                font-style: normal;
                font-weight: 400;
                font-size: 32px;
                line-height: 38px;
                text-transform: uppercase;
                color: $white;
                cursor: pointer;
                transition: all 0.4s ease-in-out;

                &:hover {
                    color: $colorAccent2;
                }

                &:first-of-type {
                    border-top: 1px solid rgba($white, 0.3);
                }
            }
        }
    }

    &-btn {
        position: absolute;
        bottom: 25px;
        display: inline-block;
        width: calc(100% - 40px);
        padding: 22px 50px;
        border: 0.5px solid $grey;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $white;
        background: linear-gradient(180deg, rgba(21, 22, 26, 0.5) -31.63%, rgba(43, 44, 47, 0.5) 82.65%);
        backdrop-filter: blur(20px);
        border-radius: 54px;
    }

    &-language {
        margin-top: 13px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: $white;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
            color: $colorAccent2;
        }
    }
}
