.policies-wrapper {
  padding: 80px 20px;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .button {
    position: relative;
    overflow: hidden;
    margin-bottom: 60px;

    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: 100%;
      left: 50%;
      top: 50%;
      opacity: 0;
      background: $tile;
      filter: blur(15.5px);
      border-radius: 27px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
    }

    &:hover,
    &.is-active {
      border: 0.5px solid $light;

      &::before {
        opacity: 0.6;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .title1 {
    width: 100%;
    max-width: calc(100% / 3);
  }

  .policies-sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 235px;

    @media (max-width: 767px) {
      margin-top: 30px;
      margin-bottom: 180px;
    }
  }

  .policies-section {
    display: flex;
    width :100%;
    padding: 20px 0;
    border-bottom: 1px solid $grey;

    &:first-child {
      padding-top: 0;
      padding-bottom: 60px;
    }

    @media (max-width: 767px) {
      flex-direction: column;

      &:first-child {
        padding-top: 0;
        padding-bottom: 30px;
      }
    }
  }

  .policies-section-title {
    width: 100%;
    max-width: calc(100% / 3);
    padding-right: 40px;
    color: $grey;
    font-weight: 400;
    text-transform: none;
  }

  .policies-section-content {
    width: 100%;
    max-width: calc((100% / 3) * 2);
  }

  p {
    margin: 0 0 40px;
    font-weight: 300;
    color: $white;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 40px;
    color: $white;
  }

  h2 {
    @extend h4;
    @extend .bold;
  }

  ul {
    color: $white;
  //  margin: 0 0 40px;
  //  padding: 0;
  //  list-style: none;
  //
  //  li {
  //    position: relative;
  //    padding-left: 30px;
  //
  //    &:before {
  //      position: absolute;
  //      content: '';
  //      left: 0;
  //      top: 8px;
  //      width: 11px;
  //      height: 11px;
  //      border-radius: 11px;
  //      border: 2px solid $colorAccent1;
  //    }
  //  }
  }

  img {
    margin: 0 auto 40px;
    max-width: 100%;
    height: 100%;
  }

  a::not(.button) {
    text-decoration: underline;
    color: $colorAccent1;
  }
}

.cookies-sections {
  *:not(a) {
    color: $white !important;
  }

  a {
    text-decoration: underline !important;
    color: $colorAccent1 !important;
  }
}
