.carousel-section {
  color: $light;
  padding-top: 100px;

  @media (max-width: 767px) {
    padding-top: 50px;
  }

  .carousel-header {
    .carousel-title {
      @extend .title2;

      @media (max-width: 767px) {
        font-size: 55px;
        line-height: 66px;
      }

      @media (max-width: 475px) {
        font-size: 32px;
        line-height: 120%;
      }
    }

    .carousel-subtitle {
      margin-top: 10px;
      max-width: 810px;
      @extend .p28;

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 130%;
      }
    }
  }

  .carousel-wrapper {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .carousel-navigation {
    display: flex;
    align-items: flex-end;
    margin: 80px 0 25px;

    @media (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
      align-self: center;
      flex-direction: column;
      justify-content: center;
    }

    .carousel-arrow-nav {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
        justify-content: left;
      }

      .carousel-arrow-container {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 70%;
          height: 70%;
          background: $tile;
          border: 0.5px solid $white;
          filter: blur(15.5px);
          border-radius: 27px;
          opacity: 0;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease;
        }

        &:hover {
          background: transparent;

          &::before {
              opacity: 0.6;
          }
        }

        &:first-child {
          margin-right: 20px;
        }
      }

      svg {
        position: relative;
        z-index: 1;
        cursor: pointer;

        @media (max-width: 1024px) {
          width: 70px;
          height: 70px;
        }

        @media (max-width: 500px) {
          width: 50px;
          height: 50px;
        }
      }
    }

    .carousel-pager-nav {
      display: flex;
      margin-left: auto;
      width: 100%;
      gap: 6px;
      justify-content: right;

      @media (max-width: 767px) {
        width: 100%;
        margin: auto;
        justify-content: center;
      }

      .carousel-pager {
        position: relative;
        width: 100%;
        max-width: 65px;
        height: 5px;
        border-radius: 38px;
        background: rgba(131, 128, 128, 0.5);
        cursor: pointer;

        @media (max-width: 767px) {
          max-width: initial;
        }

        &:after {
          content: '';
          display: block;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          max-width: 65px;
          height: 5px;
          background: $tile;
          filter: blur(5px);
          border-radius: 38px;
          opacity: 0;
          transition: opacity .3s ease-in-out;
        }

        &.is-active,
        &:hover {
          background: $tile;
          box-shadow: -12px -12px 24px rgba(62, 62, 62, 0.7), 12px 12px 24px rgba(30, 30, 30, 0.7);
          backdrop-filter: blur(15px);

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .carousel-slides {
    @media (max-width: 767px) {
      margin-top: 20px;
    }

    .carousel-slide {
      display: flex;
      align-items: stretch;
      width: 100%;
      max-height: 500px;

      @media (max-width: 900px) {
        max-height: initial;
        flex-direction: column;
      }

      .carousel-image {
        width: 100%;
        flex: auto;
        background-size: cover;
        background-position: center;

        @media (max-width: 900px) {
          max-width: initial;
          min-height: 190px;
          padding-top: 46.5%;
        }
      }

      .carousel-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 345px;
        background: $darkGrey;
        padding: 55px 35px 100px;
        overflow-y: scroll;

        @media (max-width: 900px) {
          max-width: initial;
          padding-bottom: 55px;
        }

        .slide-title {
          @extend .title4;
          text-transform: uppercase;

          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 130%;
          }
        }

        .slide-content {
          @extend .p18;
          margin-top: 30px;

          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 130%;
          }
        }
      }
    }
  }

  .divider {
    position: relative;
    height: 1px;
    margin-top: 100px;
    background-color: $grey;

    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
  }
}

.promotion-section {
  margin-top: 100px;

  @media (max-width: 900px) {
    margin-top: 50px;
  }

  .promotion-wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .promotion-content {
    margin-right: 80px;
    color: $grey;

    @media (max-width: 900px) {
      width: 100%;
      margin: 25px 0 0;
    }

    .promotion-title {
      @extend .title2;
      max-width: 420px;
      margin-bottom: 40px;
      color: $white;
      text-transform: uppercase;

      @media (max-width: 1024px) {
        font-size: 60px;
        line-height: 74px;
        margin-bottom: 30px;
      }

      @media (max-width: 767px) {
        font-size: 48px;
        line-height: 58px;
      }
    }

    .promotion-date {
      max-width: 230px;
      font-family: $fontNeueMontreal;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 130%;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media (max-width: 1024px) {
        font-size: 24px;
      }
    }

    .promotion-redactor {
      margin-bottom: 20px;
      font-family: $fontNeueMontreal;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;

      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 130%;
      }

      strong, b {
        color: $light;
      }
    }

    .promotion-map-link {
      margin-top: 20px;
      font-family: $fontNeueMontreal;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 130%;
      color: $light;
      transition: color .3s ease-in-out;

      @media (max-width: 1024px) {
        font-size: 24px;
      }

      @media (max-width: 767px) {
        font-size: 20px;
      }

      &:hover {
        color: $tile;
      }
    }

    .promotion-link {
      text-align: center;
      margin-top: 40px;
      font-size: 20px;
      line-height: 24px;
      box-shadow: -12px -12px 24px rgba(62, 62, 62, 0.7), 12px 12px 24px rgba(30, 30, 30, 0.7);
      border-radius: 27px;
      transition: background .3s ease-in-out;

      @media (max-width: 1024px) {
        font-size: 16px;
      }

      @media (max-width: 767px) {
        display: inline-block;
        text-align: center;
        width: 100%;
      }

      &:hover {
        background: $beige;
      }
    }
  }

  .promotion-video {
    width: 60%;
    margin-left: auto;

    @media (max-width: 900px) {
      max-width: initial;
      width: 100%;
      margin: 0;
    }

    .video {
      position: relative;
      width: 100%;
      max-width: 900px;
      max-height: 542px;
      border-radius: 30px;

      &.iframe-video {
        padding-bottom: 56.25%;
      }


      @media (max-width: 767px) {
        display: none;
      }

      &.mobile {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
      }
    }
  }

  .divider {
    position: relative;
    height: 1px;
    margin-top: 100px;
    background-color: $grey;

    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
  }
}

.visual-tagline-section {
  margin: 142px auto 136px;

  @media (max-width: 1024px) {
    margin: 80px auto;
  }

  .visual-tagline-row {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &.is-left {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }

      .visual-tagline-img {
        margin-right: 30px;

        @media (max-width: 767px) {
          margin: 15px 0 0;
        }
      }
    }

    &.is-right {
      @media (max-width: 767px) {
        flex-direction: column;
      }

      .visual-tagline-img {
        margin-left: 30px;

        @media (max-width: 767px) {
          margin: 15px 0 0;
        }
      }
    }

    p {
      color: $light;
      font-family: $fontNeueMontreal;
      font-style: normal;
      font-weight: 400;
      font-size: 121.277px;
      line-height: 146px;
      text-transform: uppercase;

      @media (max-width: 1024px) {
        font-size: 80px;
        line-height: 100px;
      }

      @media (max-width: 767px) {
        font-size: 55px;
        line-height: 66px;
      }
    }

    .visual-tagline-img {
      border-radius: 60px;
      background-position: center;
      background-size: cover;
      height: 125px;

      @media (max-width: 1024px) {
        height: 100px;
      }

      &.first-row {
        width: 480px;

        @media (max-width: 1024px) {
          width: 400px;
        }

        @media (max-width: 767px) {
          width: 317px;
        }
      }

      &.second-row {
        width: 217px;

        @media (max-width: 767px) {
          width: 317px;
        }
      }

      &.third-row {
        width: 362px;

        @media (max-width: 1024px) {
          width: 330px;
        }

        @media (max-width: 767px) {
          width: 317px;
        }
      }

      &.fourth-row {
        width: 461px;

        @media (max-width: 1024px) {
          width: 400px;
        }

        @media (max-width: 767px) {
          width: 317px;
        }
      }
    }
  }
}

.credits-section {
  padding-bottom: 1200px;
  background-image: url('../img/site/credits-bg.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: $light;

  @media (max-width: 1100px) {
    padding-bottom: 1000px;
  }

  @media (max-width: 767px) {
    padding-bottom: 800px;
  }

  .credits-wrapper {
    max-width: 965px;
    margin-left: auto;
    padding: 20px 0 23px;

    .credits-divider {
      position: relative;
      height: 1px;
      background-color: $grey;
    }

    .credits-row {

      &:not(:last-child) {
        padding-bottom: 20px;
      }

      &:not(:first-child) {
        padding-top: 20px;
      }

      .credits-type {
        @extend .p18;
        color: $lightGrey;
        text-transform: uppercase;
        min-width: 405px;

        @media (max-width: 767px) {
          min-width: initial;
          font-size: 16px;
          line-height: 130%;
        }
      }

      .credits-title {
        max-width: 405px;
        font-family: $fontNeueMontreal;
        font-weight: 400;
        font-size: 48px;
        line-height: 58px;

        @media (max-width: 767px) {
          font-size: 32px;
          line-height: 38px;
          max-width: 333px;
        }
      }
    }
  }
}
