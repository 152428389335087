// --------------------------------------------------
// Import Fonts
// --------------------------------------------------

@font-face {
  font-family: 'Neue Montreal';
  src: url('../fonts/NeueMontreal-Bold.eot');
  src: url('../fonts/NeueMontreal-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NeueMontreal-Bold.woff2') format('woff2'),
      url('../fonts/NeueMontreal-Bold.woff') format('woff'),
      url('../fonts/NeueMontreal-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('../fonts/NeueMontreal-Regular.eot');
  src: url('../fonts/NeueMontreal-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NeueMontreal-Regular.woff2') format('woff2'),
      url('../fonts/NeueMontreal-Regular.woff') format('woff'),
      url('../fonts/NeueMontreal-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
