.reviews {
    padding-top: 100px;
    padding-bottom: 100px;

    @media screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &-title {
        @extend .title2;
        width: 100%;
        text-transform: uppercase;
        color: $white;

        @media screen and (max-width: 768px) {
            font-size: 60px;
            line-height: 72px;
        }

        @media (max-width: 475px) {
          font-size: 32px;
          line-height: 120%;
        }
    }

    &-container {
        position: relative;
        margin-top: 50px;
        height: 100%;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
    }

    .custom-arrow-nav {
        display: flex;
        align-items: center;
        margin: 30px 0 0;

        .custom-arrow-container {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 70%;
                height: 70%;
                background: $tile;
                border: 0.5px solid $white;
                filter: blur(15.5px);
                border-radius: 27px;
                opacity: 0;
                transform: translate(-50%, -50%);
                transition: all 0.3s ease;
            }

            &:hover {
                background: transparent;
                &::before {
                  opacity: 0.6;
                }
            }

            &:first-child {
                margin-right: 20px;
            }

            svg {
                position: relative;
                z-index: 1;
                cursor: pointer;
                width: 70px;
                height: 70px;

                @media (max-width: 767px) {
                  width: 50px;
                  height: 50px;
                }
            }
        }
  }

    .review {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 325px;
        margin-right: 20px;
        border-radius: 8px;
        backdrop-filter: blur(15px);

        &:last-of-type {
            margin-right: 0;
        }

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: rgba($darkGrey, .5);
            transform: rotate(-180deg);
            transition: all 0.4s ease-in-out;
        }

        @media screen and (min-width: 600px) {
            &:hover {
                &::before {
                    background: linear-gradient(179.95deg, rgba(14, 28, 54, 0.6) 0.04%, rgba(46, 106, 130, 0.6) 34.39%, rgba(71, 168, 189, 0.6) 61.45%, rgba(175, 213, 234, 0.6) 99.96%);
                }

                .review-link {
                    color: $white;
                }
            }
        }

        @media screen and (max-width: 599px) {
            max-width: 80%;

            &.is-selected {
                &::before {
                    background: linear-gradient(179.95deg, rgba(14, 28, 54, 0.6) 0.04%, rgba(46, 106, 130, 0.6) 34.39%, rgba(71, 168, 189, 0.6) 61.45%, rgba(175, 213, 234, 0.6) 99.96%);
                }

                .review-link {
                    color: $white;
                }
            }
        }

        &-content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 20px;

            @media screen and (max-width: 768px) {
                padding: 35px 20px;
            }
        }

        &-image {
            width: 100%;
            margin-bottom: 40px;

            img {
                display: block;
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 35px;
            }
        }

        &-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $white;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        &-text {
            margin-top: 20px;
            font-style: italic;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #E0E0E0;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        &-link {
            display: inline-block;
            margin-top: 20px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: $tile;
            transition: all 0.4s ease-in-out;
        }
    }

    &-divider {
        position: relative;
        height: 1px;
        margin-top: 100px;
        background-color: $grey;

        @media screen and (max-width: 767px) {
            margin-top: 50px;
        }
    }
}
