.faq-section {
  margin-bottom: 50px;
  padding: 135px 0 500px;
  background-image: url('../img/site/faq-bg.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 767px) {
    padding: 70px 0 300px;
  }
}

.faq-main-wrapper {
  display: flex;
  color: $light;
  padding: 0 40px 500px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .faq-wrapper {
    width: 100%;
    margin-left: 115px;

    @media (max-width: 1024px) {
      margin-left: 0;
    }

    .faq-tabs-desktop {
      position: relative;
      width: 100%;
      display: flex;
      overflow: auto;
      margin-top: 60px;
      margin-bottom: 98px;

      @media (max-width: 1024px) {
        margin-top: 40px;
        margin-bottom: 60px;
      }

      @media (max-width: 767px) {
        display: none;
      }

      .button {
        position: relative;
        overflow: hidden;
        display: flex;
        margin: 0.5px;
        border: none;
        text-align: center;
        max-width: 300px;
        flex-shrink: 0;
        align-items: center;

        &:not(:last-child) {
          margin-right: 15px;
        }

        &::before {
          content: '';
          position: absolute;
          width: 80%;
          height: 100%;
          left: 50%;
          top: 50%;
          opacity: 0;
          background: $light;
          filter: blur(15.5px);
          border-radius: 27px;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease-in-out;
        }

        &:hover,
        &.is-active {
          margin-top: 0;
          margin-bottom: 0;
          border: 0.5px solid $light;

          &::before {
            opacity: 0.6;
          }
        }
      }
    }

    .faq-tabs-mobile {
      display:  none;
      margin-top: 66px;
      margin-bottom: 30px;
      border-radius: 60px;
      background: rgba(29, 33, 38, 0.6);
      border: 0.5px solid $white;
      backdrop-filter: blur(15px);
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;

      @media (max-width: 767px) {
        display: block;
      }

      &.is-active {
        border-radius: 70px;
        background: linear-gradient(180deg, rgba(21, 22, 26, 0.5) -31.63%, rgba(43, 44, 47, 0.5) 82.65%);
        backdrop-filter: blur(20px);

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100px;
          background: linear-gradient(2.43deg, #111111 1.82%, rgba(17, 17, 17, 0) 88.49%);
        }

        .select-faq-button {
          .button-icon {
            transform: rotate(180deg);
          }
        }

        .select-faq-content {
          height: 350px;
          padding: 20px 0 100px;
          border-color: rgba(255, 255, 255, 0.3);
        }
      }

      .select-faq-button {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 20px 40px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &::before {
          content: '';
          position: absolute;
          width: 80%;
          height: 100%;
          left: 50%;
          top: 50%;
          opacity: 0;
          background: #AFD5EA;
          filter: blur(15.5px);
          border-radius: 27px;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease-in-out;
        }

        &::before {
          opacity: 0.4;
        }

        .button-icon {
          margin-left: auto;
          width: 24px;
          height: 24px;
          fill: $white;
          transform: rotate(180deg);
          transition: transform .3s ease-in-out;
        }
      }

      .select-faq-content {
        position: relative;
        overflow-y: scroll;
        height: 0;
        padding: 0;
        border-top: 1px solid transparent;
        transition: all 0.3s ease-in-out;

        p {
          cursor: pointer;
          padding: 0 40px 20px;
          border-bottom: 0.5px solid $white;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }

    .faq-container {
      display: none;

      &.is-open {
        display: block;
      }
    }

    .faq-divider {
      position: relative;
      height: 1px;
      background-color: $grey;
    }

    .faq-row {
      padding: 20px 0;

      &.is-hidden {
        display: none;
      }

      &.is-open {
        .faq-arrow {
          transform: rotate(-180deg);
        }

        .faq-content {
          margin-top: 20px;
          max-height: 100%;
          padding: 0 0 40px;
          opacity: 1;

          @media (max-width: 767px) {
            padding-bottom: 20px;
          }
        }
      }
    }

    .faq-header {
      @extend .p24;
      display: flex;
      align-items: center;
      cursor: pointer;

      @media (max-width: 767px) {
        font-weight: 400;
        font-size: 18px;
      }

      p {
        padding-right: 20px;
      }

      .faq-arrow {
        margin-left: auto;
        transition: transform .3s ease-in-out;
      }
    }

    .faq-content {
      max-height: 0;
      padding-bottom: 0;
      transition: max-height 0.25s ease-in-out, padding-bottom 0.25s ease-in-out, opacity 0.25s ease-in-out;
      will-change: max-height;
      opacity: 0;
      pointer-events: none;
      @extend .p20;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}
