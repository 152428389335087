.splitImageText {
    position: relative;
    padding-top: 100px;

    @media screen and (max-width: 768px) {
        padding-top: 50px;
    }

    &-tagline {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 72px;
        text-transform: uppercase;
        color: $white;

        @media screen and (max-width: 768px) {
            font-size: 36px;
            line-height: 43px;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-top: 50px;

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            margin-top: 30px;
        }
    }

    &-image {
        width: 50%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        @media screen and (max-width: 1100px) {
            width: 100%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 0 50px;

        @media screen and (max-width: 1100px) {
            width: 100%;
            margin-top: 25px;
            padding: 0;
        }
    }

    &-title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
        color: $white;

        @media screen and (max-width: 768px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &-text {
        margin-top: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: $white;

        @media screen and (max-width: 768px) {
            margin-top: 25px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-divider {
        position: relative;
        height: 1px;
        margin-top: 100px;
        background-color: $grey;

        @media screen and (max-width: 767px) {
            margin-top: 50px;
        }
    }
}
