// Titles
h1,
.title1 {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 120px;
  /* identical to box height */
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 1100px) {
    font-size: 80px;
    line-height: 100px;
  }

  @media (max-width: 830px) {
    font-size: 55px;
    line-height: 66px;
  }
}

h2,
.title2 {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 105%;
  margin: 0;

  &.bold, strong {
    font-weight: 800;
  }

  @media (max-width: 1100px) {
    font-size: 54px;
  }

  @media (max-width: 830px) {
    font-size: 32px;
    line-height: 120%;
  }
}

h3,
.title3 {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 120%;
  margin: 0;

  &.bold, strong {
    font-weight: 800;
  }

  @media (max-width: 1100px) {
    font-size: 48px;
    line-height: 58px;
  }

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 34px;
  }
}

h4,
.title4 {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  margin: 0;

  &.bold {
    font-weight: 700;
  }

  @media (max-width: 1100px) {
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 130%;
  }
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}

// Texts
.p28 {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 130%;
}

.p24 {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
}

.p20 {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
}

.p18 {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.p16 {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
}

.titleBold {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.regularText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}

.caption {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 115%;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}
