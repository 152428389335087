// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
// @import url(...);

// Fonts
$fontNeueMontreal: 'Neue Montreal', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #0A91A6;
$colorAccent2: #2E6A82;
$tile: #47A8BD;

// Base Colors
$black: #111111;
$darkGrey: #1D2126;
$grey: #9A9A9A;
$lightGrey: #F0F0F0;
$light: #F1F1F1;
$white: #FFFFFF;

// Other Colors
$beige: #F5EEE4;
$burnedOrange: #AF3800;

// Status Colors
$success: #00966D;
$error: #FF564A;

// Overwrite AOS distance
$aos-distance: 20px;
