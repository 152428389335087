.partners-wrapper {
  color: $light;
  padding-top: 135px;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    padding-top: 70px;
  }

  .partners-header {
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 50px;

      @media (max-width: 1024px) {
        margin-bottom: 30px;
      }
    }

    .partners-header-content {
      max-width: 902px;
      margin-left: auto;

      @media (max-width: 1024px) {
        max-width: initial;
        margin: 0;
      }

      img {
        width: 100%;
        margin-bottom: 50px;
        border-radius: 30px;

        @media (max-width: 1024px) {
          margin-bottom: 25px;
        }
      }

      .title3 {
        max-width: 880px;
        text-transform: uppercase;
      }
    }
  }

  .partners-section {
    margin: 100px 0 200px;
    padding: 20px 0;

    @media (max-width: 900px) {
      margin: 50px 0 100px;
    }

    .partners-divider {
      position: relative;
      height: 1px;
      background-color: $grey;
    }

    .partners-row {
      display: flex;

      @media (max-width: 905px) {
        flex-direction: column;
      }

      &:not(:first-child) {
        padding-top: 20px;
      }
    }

    .partners-title {
      width: 265px;
      margin-right: calc(10% + 40px);
      flex-shrink: 0;

      @media (max-width: 767px) {
        margin-right: 0;
        width: 100%;
      }

      p {
        @extend .title4;
        color: $grey;

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 130%;
        }
      }
    }

    .partners-logo {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 35px;
      max-width: 890px;

      @media (max-width: 905px) {
        max-width: initial;
        gap: 28px;
      }

      @media (max-width: 545px) {
        justify-content: space-between;
      }

      .partner-logo {
        display: flex;
        align-items: center;
        width: 150px;
        height: 150px;

        img {
          width: 100%;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }

  .offers-section {
    .title2 {
      margin-bottom: 50px;
      text-transform: uppercase;
    }

    .offer-wrapper {
      display: flex;
      width: 100%;
      padding: 50px;
      background: $darkGrey;
      border-radius: 30px;

      @media (max-width: 900px) {
        padding: 15px 18px;
        flex-direction: column;
      }

      .offer-image {
        flex-shrink: 0;
        width: 560px;
        height: 345px;
        margin-right: 75px;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 1050px) {
          width: 450px;
          height: 235px;
          margin-right: 50px;
        }

        @media (max-width: 900px) {
          width: 100%;
          height: 185px;
          margin-right: 0;
          margin-bottom: 30px;
        }
      }

      .offer-content {
        .title3 {
          text-transform: uppercase;
          margin-bottom: 40px;

          @media (max-width: 1050px) {
            font-size: 32px;
            line-height: 130%;
          }
        }

        .offer-text {
          @extend .p20;
          width: 100%;
          max-width: 470px;
          margin-bottom: 40px;

          @media (max-width: 1050px) {
            font-size: 18px;
          }

          @media (max-width: 900px) {
            margin-bottom: 20px;
            font-size: 16px;
            max-width: initial;
          }
        }

        .button {
          box-shadow: -12px -12px 24px rgba(62, 62, 62, 0.7), 12px 12px 24px rgba(30, 30, 30, 0.7);

          @media (max-width: 900px) {
            width: 100%;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
