.button {
  position: relative;
  overflow: hidden;
  display: flex;
  width: fit-content;
  color: $white;
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  padding: 6px 21px;
  text-transform: uppercase;
  border: 0.5px solid #FFFFFF;
  border-radius: 27px;

  &:hover {
    background-size: 110%;
  }

  &:disabled, &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.inverted {
    padding: 18px 70px;
    border: none;
    color: $black;
    background: $tile;
    transition: background .3s ease-in-out;

    &:hover {
      background: $beige;
    }

    @media (max-width: 1024px) {
      padding: 18px 40px;
    }
  }
}

.button-animated {
  position: relative;
  overflow: hidden;

  &.small {
    &::before {
      width: 74px;
      animation: button-translate-small 8s ease-in-out infinite;
    }
  }

  &.big {
    &::before {
      width: 125px;
      animation: button-translate-big 14s ease-in-out infinite;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -1;
    width: 105px;
    height: 100%;
    border-radius: 30px;
    background: $tile;
    opacity: 0.6;
    filter: blur(17px);
    transform: translate(-50%, -50%);
    animation: button-translate 11s ease-in-out infinite;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 80%;
    height: 100%;
    border-radius: 30px;
    background: $tile;
    opacity: 0;
    filter: blur(17px);
    transform: translate(-50%, -50%);
    transition: all 0.6s ease-in-out;
  }

  &:hover {
    &::before {
      content: none;
      animation-play-state: paused;
    }

    &:after {
      opacity: 0.6;
    }
  }
}

@keyframes button-translate-small {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(150%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes button-translate {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(300%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes button-translate-big {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(375%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.ticketing-btn-mobile {
  //position: fixed;
  //bottom: 25px;
  //left: 50%;
  //right: auto;
  z-index: 20;
  overflow: hidden;
  display: none;
  width: calc(100% - 40px);
  max-width: 100px;
  padding: 10px 20px;
  margin-right: 60px;
  margin-left: auto;
  border: 0.5px solid $grey;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $white;
  background: linear-gradient(180deg, rgba(21, 22, 26, 0.5) -31.63%, rgba(43, 44, 47, 0.5) 82.65%);
  backdrop-filter: blur(20px);
  border-radius: 54px;
  //transform: translateX(-50%);

  @media screen and (max-width: 767px) {
    display: inline-block;
  }
}

