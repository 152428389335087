.promoBanner {
    position: relative;
    overflow: hidden;
    z-index: 15;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    transition: all 0.3s ease-in-out;

    &.is-blue {
        background-color: $colorAccent2;
    }

    &.is-red {
        background-color: $burnedOrange;
    }

    &.is-animated {
        .promoBanner-content {
            position: relative;
            display: flex;
            align-items: center;
            animation: marquee 30s linear infinite;
            animation-timing-function: linear;

            @media screen and (max-width: 600px) {
                animation: marquee 10s linear infinite;
            }
        }
    
        .promoBanner-text {
            white-space: nowrap;
        }

        .promoBanner-circle {
            width: 12px;
            min-width: 12px;
            height: 12px;
            margin-left: 8px;
            margin-right: 8px;
            border-radius: 50%;
            background: $white;
        }
    }

    &.is-static {
        .promoBanner-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .promoBanner-text {
            position: relative;
            padding-left: 40px;
            padding-right: 40px;
            text-align: center;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                width: 1px;
                height: calc(100% + 20px);
                background-color: $white;
                transform: translateY(-50%);
            }
            
            &::after {
                right: 0;
            }

            &::before {
                left: 0;
            }

            @media screen and (max-width: 600px) {
                &::before,
                &::after {
                    content: none;
                }
            }
        }
    }

    &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: $white;
    }
}

@keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}