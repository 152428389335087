.hero-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;

    &.has-background-asset {
        height: 100vh;

      .hero-panel-container {
        height: 100vh;
      }

        .hero-panel-video {
            position: absolute;
            inset: 0;
            height: 100%;

            .video-background {
                min-height: 100vh;
            }
        }

        .hero-panel-content {
            z-index: 2;
            height: 100%;

            @media screen and (max-width: 767px) {
                justify-content: flex-end;
                padding: 20px 20px 170px;
            }
        }
    }

    &-video {
        position: relative;
        inset: none;
        order: 2;
        width: 100%;
        height: 70vh;
        overflow: hidden;
        background: #000;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .video-background {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100vw;
            height: 56.25vw;
            min-height: 20vh;
            min-width: 177.77vh;
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);

            &.mobile {
                display: none;
                min-width: 55.55556vh;
                min-height: 180vw;
            }
        }

        @media screen and (max-width: 767px) {
            height: 30vh;

            .video-background {
                display: none;

                &.mobile {
                    display: block;
                }
            }
        }
    }

    &-image {
      order: 2;
      margin-top: 60px;
      width: 100%;
      height: 70vh;
      background-size: cover;
      background-position: center;

      @media (max-width: 767px) {
        margin-top: 0;
        height: 60vh;
      }

      img {
        width: 100%;
      }
    }

    &-container {
        width: 100%;
        order: 1;
    }

    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 25px;

        @media screen and (max-width: 767px) {
            padding: 40px 20px 30px;
        }
    }

    &-title {
        font-style: normal;
        font-weight: 400;
        font-size: 79.4509px;
        line-height: 100%;
        text-transform: uppercase;
        color: $white;

        @media screen and (max-width: 767px) {
            font-size: 28px;
            line-height: 34px;
        }
    }

    &-text {
        margin-top: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 130%;
        color: $white;

        @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 140%;
        }
    }
}
