.ticketing-container {
  background-color: $black;
  background-size: contain;
  padding: 90px 0;
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    padding: 40px 0;
  }

  &.tickx {
    padding: 0;
  }

  .max-width {
    display: flex;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .ticketing-hero {
    position: relative;
    height: 70vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      height: 30vh;
    }

    .max-width {
      flex-direction: column;
      justify-content: center;
      height: 100%;

      h1, p {
        color: $white;
      }
    }
  }

  .ticketing-content {
    padding-top: 50px;
    padding-bottom: 90px;

    &.for-mobile {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }

    &.for-desktop {
      display: block;

      @media (max-width: 767px) {
        display: none;
      }
    }

    h2, p {
      color: $white;
    }

    .ticketing-widget-wrapper {
      margin-top: 50px;
    }
  }

  .ticketing-content-wrapper {
    width: calc(70% - 60px);
    max-width: 780px;
    color: $white;
    margin-right: 90px;

    @media (max-width: 1024px) {
      width: 100%;
      margin-right: 0;
      max-width: initial;
    }

    .ticketing-header {
      margin-bottom: 60px;

      @media (max-width: 767px) {
        margin-bottom: 25px;
      }

      h1 {
        margin-bottom: 30px;

        @media (max-width: 767px) {
          margin-bottom: 50px;
        }
      }

      .title4 {
        @media (max-width: 767px) {
          font-size: 32px;
          line-height: 38px;
        }
      }
    }

    .ticketing-content {
      @extend .p20;

      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 130%;
      }
    }

    .button {
      margin-bottom: 50px;
      box-shadow: -12px -12px 24px rgba(62, 62, 62, 0.7), 12px 12px 24px rgba(30, 30, 30, 0.7);

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 30px;
        justify-content: center;
      }
    }
  }

  .ticketing-widget-wrapper {
    display: none;
    width: 40%;
    max-width: 440px;

    @media (max-width: 1024px) {
      width: 100%;
      max-width: initial;
      margin-top: 50px;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: initial;
      margin-top: 50px;
    }

    iframe {
      min-height: 750px;
    }
  }

  .ticketing-image {
    margin-top: 140px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }

    img {
      width: 100%;
      max-width: 602px;
      border-radius: 30px;
    }
  }
}

.ticketing-widget-container {
  flex-direction: column;

  .ticketing-widget-wrapper {
    display: block;
    width: 100%;
    max-width: initial;
  }
}
