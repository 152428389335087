.article-wrapper {
  width: 100%;
  max-width: 682px;
  margin: auto;
  padding: 135px 0;
  color: $light;

  @media (max-width: 1024px) {
    padding: 135px 40px;
  }

  @media (max-width: 767px) {
    padding: 135px 20px;
  }

  .button {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 100%;
      background: $tile;
      border: 0.5px solid $white;
      filter: blur(15.5px);
      border-radius: 27px;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;
    }

    &:hover {
      background: transparent;

      &::before {
        opacity: 0.6;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  .article-content-wrapper {
    margin-top: 30px;

    .article-info {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      @media (max-width: 1024px) {
        margin-top: 14px;
      }

      .article-source {
        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 140%;
        }
      }

      .separator {
        display: block;
        width: 1px;
        height: 19px;
        margin: 0 15px;
        background-color: $light;
      }

      .article-date {
        color: $grey;

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 130%;
        }
      }
    }

    .article-content {
      .media-wrapper {
        margin-bottom: 30px;
        border-radius: 30px;
        overflow: hidden;

        img {
          width: 100%;
          border-radius: 30px;
        }

        video {
          width: 100%;
          border-radius: 30px;

          @media (max-width: 767px) {
            display: none;
          }

          &.mobile {
            display: none;

            @media (max-width: 767px) {
              display: block;
            }
          }
        }
      }

      p {
        @extend .p20;
        margin: 30px 0;

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 130%;
        }
      }
    }
  }
}
